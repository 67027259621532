import AppNavbar from "../components/AppNavbar"
import Banner from "../components/Banner"

export default function Home() {
  return (
  	<>
        <AppNavbar />
        <Banner />
  	</>
  );
}