import { Col, Image } from 'react-bootstrap';

import WarehouseImage from '../assets/Warehouse.png';

export default function Warehouse() {

  return (
  	<>
        <Col xs={12} md={12} lg={4}>
          <div className="mx-auto pt-5 pb-3">
            <h1 className="text-uppercase text-start banner-name">M. Ignacio Warehouse</h1>
	            <a 
							  href="https://mignaciowarehouse.com/" 
							  target="_blank" 
							  rel="noopener noreferrer"
							  className="no-decoration"
							>
	            	<h6 className="banner-link">https://mignaciowarehouse.com/</h6>
	            </a>
            <p className="pt-3 banner-text text-center d-none d-lg-block">
              <a>M. Ignacio Warehouse</a> is a dynamic web application designed to effectively showcase a warehouse and its essential functionalities. Developed using <a>React.js</a> and enhanced with the modern styling capabilities of <a>Tailwind CSS</a>, this platform provides an intuitive user experience while highlighting the key features of the warehouse. 
            </p>
          </div>
        </Col>
        <Col xs={12} md={12} lg={8}>
            <div className="mx-auto pt-5 pb-3">
              <a href="https://mignaciowarehouse.com/" target="_blank" rel="noopener noreferrer">
                <Image fluid className="main-image" src={WarehouseImage} />
              </a>
            </div>
        </Col>
    </>
  );
}