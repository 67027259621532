import { Col, Image } from 'react-bootstrap';

import BizBuddyImage from '../assets/BizBuddy.png';

export default function BizBuddy() {

  return (
  	<>
        <Col xs={12} md={12} lg={4}>
          <div className="mx-auto pt-5 pb-3">
            <h1 className="text-uppercase text-start banner-name">BizBuddy</h1>
	            <a 
							  href="https://biz-buddy.vercel.app/login" 
							  target="_blank" 
							  rel="noopener noreferrer"
							  className="no-decoration"
							>
	            	<h6 className="banner-link">https://biz-buddy.vercel.app/login</h6>
	            </a>
            <p className="pt-3 banner-text text-center d-none d-lg-block">
              <a>BizBuddy</a> is a web application designed for employee time-keeping. Built with <a>React.js</a> and powered by <a>Material UI</a>. 
            </p>
          </div>
        </Col>
        <Col xs={12} md={12} lg={8}>
            <div className="mx-auto pt-5 pb-3">
              <a href="https://biz-buddy.vercel.app/login" target="_blank" rel="noopener noreferrer">
                <Image fluid className="main-image" src={BizBuddyImage} />
              </a>
            </div>
        </Col>
    </>
  );
}