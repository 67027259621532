import { Col, Image } from 'react-bootstrap';

import BizSolutionsImage from '../assets/BizSolutions.png';

export default function BizSolutions() {

  return (
  	<>
        <Col xs={12} md={12} lg={4}>
          <div className="mx-auto pt-5 pb-3">
            <h1 className="text-uppercase text-start banner-name">BizSolutions LLC</h1>
	            <a 
							  href="https://mybizsolutions.us/" 
							  target="_blank" 
							  rel="noopener noreferrer"
							  className="no-decoration"
							>
	            	<h6 className="banner-link">https://mybizsolutions.us/</h6>
	            </a>
            <p className="pt-3 banner-text text-center d-none d-lg-block">
              <a>BizSolutions LLC</a> is a web application designed for business directors, catering to requests from around the globe. Built with <a>React.js</a> and powered by <a>Bootstrap</a>, it serves as your partner for all your business needs, including web development and revamp, sales and collections, as well as BPO and bookkeeping services.
            </p>
          </div>
        </Col>
        <Col xs={12} md={12} lg={8}>
            <div className="mx-auto pt-5 pb-3">
              <a href="https://mybizsolutions.us/" target="_blank" rel="noopener noreferrer">
                <Image fluid className="main-image" src={BizSolutionsImage} />
              </a>
            </div>
        </Col>
    </>
  );
}