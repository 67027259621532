import { Col, Container, Image, Row } from 'react-bootstrap';
import { FaLinkedinIn, FaInstagram, FaGithub } from 'react-icons/fa';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Carlo from '../assets/1-modified.png';
import ChatHead from '../assets/2-chatHead.png';

export default function Banner() {

  useEffect(() => {
    const timer = setTimeout(() => {
      const chatHeadElement = document.querySelector('.chat-head');
      chatHeadElement.classList.add('show-chat-head');
    }, 3000); // 3000 milliseconds = 3 seconds
  }, []);

  return (
    <Container>
      <Row className="align-items-center">
        {/* Left Column */}
      <Col xs={12} md={12} lg={6}>
      <div className="pt-5 pb-3 image-container">
        <Link to="/projects">
          <Image fluid className="main-image" src={Carlo} />
          <Image fluid className="chat-head" src={ChatHead} />
        </Link>
      </div>
      </Col>
        {/* Right Column */}
        <Col xs={12} md={12} lg={6}>
          <div className="mx-auto pt-5 pb-3 text-center">
            <h1 className="text-uppercase banner-name">Carlo Corcuera</h1>
            <h6 className="banner-name">@carloicorcuera</h6>
            <div>
              <a className="py-2 px-3" href="https://www.linkedin.com/in/carlo-i-corcuera/" target="_blank" rel="noreferrer">
                <FaLinkedinIn color="#ED370A" size="1.5em" title="LinkedIn"/>
              </a>
              <a className="py-2 px-3" href="https://www.instagram.com/carloicorc/" target="_blank" rel="noreferrer">
                <FaInstagram color="#ED370A" size="1.5em" title="Instagram"/>
              </a>
              <a className="py-2 px-3" href="https://github.com/cheeios" target="_blank" rel="noreferrer">
                <FaGithub color="#ED370A" size="1.5em" title="GitHub"/>
              </a>
            </div>
            <p className="pt-3 banner-text d-none d-lg-block">
              Hi, I'm <a>Carlo Corcuera</a>, a 24-year-old <a>Full Stack Developer</a> based in <a>Manila, Philippines</a>. With a strong focus on <a>Node.js</a> and <a>backend development</a>, I design, build, and maintain web applications in my freelance work. I specialize in the <a>MERN stack</a> and have hands-on experience with <a>API development</a>, system optimization, and modern web technologies.
            </p>

          </div>
        </Col>
      </Row>
    </Container>
  );
}
