import { Container, Nav, Navbar, Button } from 'react-bootstrap';
import { FaRegFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import Resume from "../assets/carlo-corcuera-cv.pdf";

import './style.css'

export default function AppNavbar() {

  return (
    <Container>
      <Navbar expand="lg" className="bg-body-tertiarynavbar">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Navbar.Brand className="navbar-logo fs-1">carlo.</Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Button variant="link" className="me-auto">
              <a href={Resume} download="carlo-corcuera-cv.pdf">
              <FaRegFilePdf title="carlo-corcuera-cv" color="black" size="1.5em" />
              </a>   
              </Button>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}