import AppNavbar from "../components/AppNavbar"
import Projects from "../components/Projects"

export default function Home() {
  return (
  	<>
        <AppNavbar />
        <Projects />
  	</>
  );
}